const languageSelector = {

    setup : function (device) {
        this.$select = $('#languageSelector_'+device); 
        this.$selectContainer = $('.languageSelector_'+device);
        this.bindEvents();
    },

    bindEvents:  function ()  {
        this.$select.select2({
            dropdownParent: this.$selectContainer,
            width: 'resolve', // need to override the changed default
            escapeMarkup: function(m) {
                return m;
            },
            minimumResultsForSearch: -1,
            closeOnSelect: false,
            templateResult: function (result) {
                if (!result.id) return result.text;
                let myElement = $(result.element);
                return '<a href="' + $(myElement).data('url') + '"><div class="languageSelectorOption">' +
                        '<span class="testo">' + result.text + '</span>' +
                    '</div></a>';
            },
            templateSelection: function (result) {
                if (!result.id) return result.text;
                let myElementResult = $(result.element);
                return '<div class="languageSelectorOption">' +
                        '<span class="testo">' + result.text + '</span>' +
                    '</div>';
            },
        });

        this.$select.on('select2:open', function (e) {
            const evt = "scroll.select2";
            $(e.target).parents().off(evt);
            $(window).off(evt);
        }.bind(this));
    
        this.$select.on("select2:selecting", function (e) {
            e.preventDefault();
        }.bind(this));
    },
    
    init: function (device) {
          try {
                this.setup(device);
                this.bindEvents();
          }
          catch (e) {
                console.error(e);
          }
    }
};
